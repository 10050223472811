// Angular
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { GlobalGuard } from "./_core/guards/global.guard";
import { ExpiredPageComponent } from "a3dn-authentification-lib";

const routes: Routes = [
	{
		path: "",
		loadChildren: () => import("a3dn-authentification-lib").then((m) => m.AuthenticationModule),
	},
	{
		path: "auth",
		loadChildren: () => import("a3dn-authentification-lib").then((m) => m.AuthenticationModule),
	},
	{
		// routing for user
		path: "a",
		loadChildren: () => import("./app-user/app-user.module").then((m) => m.AppUserModule),
		canActivate: [GlobalGuard],
	},
	{
		// guest routing (customer of user)
		path: "g",
		loadChildren: () => import("./app-guest/app-guest.module").then((m) => m.AppGuestModule),
	},
	{ path: "session-expired", component: ExpiredPageComponent },
	{ path: "sa/session-expired", component: ExpiredPageComponent },

	{
		path: "trials/request",
		loadChildren: () =>
			import("./wrappers/trial-request-wrapper.module").then((m) => m.TrialRequestWrapperModule),
	},

	//redirections
	{ path: "assessment", redirectTo: "g/questionnaire", pathMatch: "prefix" },
	{ path: "", redirectTo: "a", pathMatch: "full" },
	{ path: "**", redirectTo: "a", pathMatch: "full" },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			onSameUrlNavigation: "reload",
			relativeLinkResolution: "legacy",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
